import React from 'react';
import logo from '../utils/logo.png';
import { Navbar } from 'react-bootstrap';
import {
  FaPhone,
  FaLocationArrow,
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaCoins,
  FaPeopleCarry,
  FaTruckLoading,
  FaStore,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer
      className="u-clearfix u-custom-color-3 u-footer paddingFooter"
      id="sec-51bf"
    >
      <div className="container">
        <div class="row mb-4 footerBox">
          <div class="col-md-3 themed-grid-col marginButton">
            <h6 className="md-2">
              <a
                href="https://nicepage.com"
                data-image-width="297"
                data-image-height="83"
                className="linkImag"
              >
                <img src={logo} alt="" className="img" />
              </a>
            </h6>
            <div className="address">
              <FaLocationArrow></FaLocationArrow>&nbsp; &nbsp;Rua da Beira 6671
              (lados e fundos) <br />
              Bairro: Lagoa <br />
              Porto Velho, RO <br />
              CEP: 76812-003 <br />
              <FaPhone></FaPhone>&nbsp;+55 69 21820182
            </div>
          </div>
          <div class="col-md-3 themed-grid-col marginButton">
            <h6 className="u-custom-font u-heading-font u-text u-text-1 ">
              PRODUTOS
              <br />
            </h6>
            <ul>
              <li>Nestlé</li>
              <li>Nestlé Purina</li>
              <li> Nestlé Professional Food</li>
              <li> Garoto</li>
            </ul>
          </div>
          <div class="col-md-3 themed-grid-col marginButton">
            <h6 className="u-custom-font u-heading-font u-text u-text-3">
              DEPARTAMENTO
            </h6>
            <ul>
              <li>
                <FaCoins></FaCoins>&nbsp;
                <a
                  href="https://whatsa.me/5569999418037"
                  rel="noreferrer"
                  className="text-white"
                  target="_blank"
                >
                  Financeiro
                </a>
              </li>
              <li>
                <FaPeopleCarry></FaPeopleCarry>&nbsp;
                <a
                  href="https://whatsa.me/5569999778681"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white"
                >
                  RH
                </a>
              </li>
              <li>
                {' '}
                <FaTruckLoading></FaTruckLoading>&nbsp;
                <a
                  href="https://whatsa.me/5569999287861"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white"
                >
                  Logística
                </a>
              </li>
              <li>
                {' '}
                <FaStore></FaStore>&nbsp;
                <a
                  href="https://whatsa.me/5569999100407"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white"
                >
                  Comercial
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-3 themed-grid-col marginButton">
            <h6 className="u-custom-font u-heading-font u-text u-text-3">
              SIGA-NOS
            </h6>
            <ul>
              <li>
                <Navbar.Text>
                  <a
                    href="https://www.instagram.com/brokernorte/"
                    className="text-white"
                  >
                    <FaInstagram size={25}></FaInstagram>
                  </a>
                </Navbar.Text>
                <Navbar.Text>
                  &nbsp; &nbsp;
                  <a
                    href="https://www.facebook.com/brokernorte"
                    className="text-white"
                  >
                    <FaFacebook size={25}></FaFacebook>
                  </a>
                </Navbar.Text>
                &nbsp; &nbsp;
                <Navbar.Text>
                  <a
                    href="https://www.linkedin.com/company/broker-norte/"
                    className="text-white"
                  >
                    <FaLinkedin size={25}></FaLinkedin>
                  </a>
                </Navbar.Text>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div class="row mb-12">
          <p className="u-text u-text-default u-text-7 text-center">
            {' '}
            © 2020 Brokernorte. Todos direitos reservdos
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

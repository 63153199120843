import React from 'react';
import './css/index.css';
import ImageAndText from './components/ImageAndText';
import ActionArea from './components/ActionArea';
import WhoDo from './components/WhoDo';
import WhoWeAre from './components/WhoWeAre';
import KnowWeEstructure from './components/KnowWeEstructure';
import 'bootstrap/dist/css/bootstrap.min.css';

const Index = () => {
  return (
    <React.Fragment>
      <ImageAndText></ImageAndText>
      <ActionArea></ActionArea>
      <WhoDo></WhoDo>
      <WhoWeAre></WhoWeAre>
      <KnowWeEstructure></KnowWeEstructure>
    </React.Fragment>
  );
};

export default Index;

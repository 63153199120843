import axios from "axios";

export const validations = (client) => {
if (client.name === '') {
  alert("Razão social obrigatorio");
}else if(client.fantasy_name === ''){
  alert("Nome fantasia obrigatorio");
}else if (client.address === '') {
  alert("Endereço obrigatorio");
}else if (client.addressNumber === '') {
  alert("Número obrigatorio");
}else if (client.complement === '') {
  alert("Complemento obrigatorio");
}else if (client.city === '') {
  alert("Cidade obrigatorio");
}else if (client.neighborhood === ''){
  alert("Bairro obrigatorio");
}else if (client.cep === '') {
  alert("CEP obrigatorio");
}else if(client.uf === ''){
  alert("Estado obrigatorio");
}else if(client.name_contact === ''){
  alert("Nome para contato obrigatorio");
}else if(client.email_contact === '') {
  alert("Email para contato obrigatorio");
}else if(client.phone === ''){
  alert("Telefone para contato obrigatorio");
}else if(client.cel_phone === ''){
  alert("Telefone para contato obrigatorio");
}else if(client.recaptcha === null){
  alert("Me diga se não é um robô");
}else{
  const headers ={
    "Authorization": "Bearer ADMI110030",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Authorization", 
    "Access-Control-Allow-Methods": "POST" ,
    "Content-Type": "application/json;charset=UTF-8"  
}
  axios.post('https://qa-broker-norte-platform.herokuapp.com/api/v1/client_sign_up/create', 
  {client}, {headers})
  .then(resp => {
      alert('Obrigado pelo seu cadastro Em breve entramos em contato')
  })
  .catch(error => {
    if (error.response.status === 422) {
      alert('Algo deu errado, por favor entre em contato pelo telefone')
    }else if(error.response.status === 400){
      alert('Algo no seu cadastro não combina com os nossos requisitos. Revise os campos.')
    }
  })
}
}

import React from 'react'
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import './index.css'
import {validations} from './utils/validations'
import ReCAPTCHA from "react-google-recaptcha";

const ClientSignUp = props =>{
  const {register, handleSubmit, setValue, setFocus} = useForm();

  const saveRecaptcha = (value) => {
    setValue('recaptcha', value)
  }

  const onSubmit = (e) => {
    validations(e)
  }

  const checkCEP = (e) => {
    const cep = e.target.value.replace(/\D/g, '');
    console.log(cep);
    fetch(`https://viacep.com.br/ws/${cep}/json/`).then(res => res.json()).then(data => {
      console.log(data);
      setValue('address', data.logradouro);
      setValue('neighborhood', data.bairro);
      setValue('city', data.localidade);
      setValue('uf', data.uf);
      setFocus('addressNumber');
    });
  }

  return(
    <div className='sectionSignUp'>
      <div className="container">
        <div className="card">
          <div className="container">
          <h3 className='card-title'>
          Cadastre sua empresa para desfrutar de toda a qualidade e benefícios que o
          Broker Norte proporciona e em breve nossa equipe retornará o contato.
          </h3>
          </div>
        <div className="card-body">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <section className='text-primary'>Dados de sua Empresa</section>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Razão social <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("name" )}/>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nome Fantasia <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("fantasy_name" )}/>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>CNPJ</Form.Label>
              <Form.Control type="text" placeholder="" {...register("cnpj" )} />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Inscrição Estadual</Form.Label>
              <Form.Control type="text" placeholder="" {...register("state_registration" )}/>
            </Form.Group>
          </div>
          
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Endereço <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="Rua, avenida alamedas, praças etc" {...register("address" )}/>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Número <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("addressNumber" )}/>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Complemento <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="Rua, avenida alamedas, praças etc" {...register("complement" )}/>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Cidade <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder=""{...register("city" )}/>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Bairro <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("neighborhood" )}/>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Estado <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("uf" )}/>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>CEP <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("cep")} onBlur={checkCEP} />
            </Form.Group>
          </div>
        </div>
        <section className='text-primary'>Seus dados para contato</section>
          <hr />
        <div className="row">
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nome <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder=""  {...register("name_contact" )}/>
            </Form.Group>
          </div>  
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>E-mail <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="email" placeholder="" {...register("email_contact" )}/>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Telefone Fixo <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("phone" )}/>
                <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Celular <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("cel_phone" )}/>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>WhatsApp <span className='text-danger'>*</span></Form.Label>
              <Form.Control type="text" placeholder="" {...register("whatsapp" )}/>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Mensagem (opcional)</Form.Label>
              <Form.Control type="text" placeholder="" {...register("mensage" )}/>
            </Form.Group>
          </div>
        </div>
        <div className="col-md-6">
          <ReCAPTCHA
            sitekey="6LfZsWgfAAAAAO7vu1w3c_lTf6WHm2r15H44oQ8T"
            onChange={saveRecaptcha}
          />
        </div>
        <br />
        <Button variant="primary" type="submit">
          Enviar
        </Button>
      </Form>
      </div>
      </div>
      </div>
    </div>
  )
}

export default ClientSignUp;

import React from 'react';
import './WhoWeAre.css';
import { Container } from 'react-bootstrap';
import {
  FaPencilAlt,
  FaHandsHelping,
  FaStar,
  FaRegCheckCircle,
} from 'react-icons/fa';

const WhoWeAre = () => {
  return (
    <Container className="whoWereSectionBox" id="sec-5d3b">
      <div className="row">
        <h2 className="text-center fontSize32">
          <b>QUEM SOMOS</b>
        </h2>
        <p className="u-align-justify u-text u-text-default u-text-2 fontSize20">
          Durante 14 anos o Mercantil Nova Era revendeu os produtos Nestlé como
          Atacado, quando em 1995 passou a ser o Distribuidor de Chocolates e
          Biscoitos da Nestlé. No ano de 2004, devido ao sucesso na
          distribuição, surgiu um novo desafio e então tornou-se Distribuidor
          Autorizado Nestlé. Desempenhando um excelente papel como DAN, o Nova
          Era foi convidado a distribuir os produtos Nestlé no formato Broker.
          Foi então que em outubro de 2013, como Broker Norte, iniciamos nossas
          atividades, sendo hoje o maior distribuidor Nestlé dos estados de
          Rondônia e Acre.
        </p>
      </div>

      <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div className="card border-0  rounded-xs pt-5">
          <div className="card-body boxWeArCustom">
            <div className="text-center">
              <FaPencilAlt size={50} className="text-dark"></FaPencilAlt>
            </div>
            <h4 className="mt-4 mb-3 text-center">Missão</h4>

            <p className="text-dark text-center">
              Distribuir produtos Nestlé com excelência na prestação dos
              serviços, garantindo a satisfação dos nossos clientes e o
              bem-estar dos consumidores.
            </p>
          </div>
        </div>

        <div className="card border-0  rounded-xs pt-5">
          <div className="card-body boxWeArCustom">
            <div className="text-center">
              <FaHandsHelping size={50} className="text-dark"></FaHandsHelping>
            </div>
            <h4 className="mt-4 mb-3 text-center text-dark">Visão</h4>

            <p className="text-dark text-center">
              Ser referência como Distribuidor Nestlé para clientes e
              colaboradores.
            </p>
          </div>
        </div>

        <div className="card border-0  rounded-xs pt-5">
          <div className="card-body boxWeArCustom">
            <div className="text-center">
              <FaStar size={50} className="text-dark"></FaStar>
            </div>
            <h4 className="mt-4 mb-3 text-center text-dark">Valores</h4>

            <p className="text-dark ">
              <ul className="text-dark">
                <li>
                  {' '}
                  <FaRegCheckCircle></FaRegCheckCircle> Ética
                </li>
                <li>
                  <FaRegCheckCircle></FaRegCheckCircle> Respeito
                </li>
                <li>
                  {' '}
                  <FaRegCheckCircle></FaRegCheckCircle> Comprometimento
                </li>
                <li>
                  {' '}
                  <FaRegCheckCircle></FaRegCheckCircle> Desenvolvimento
                  Profissional
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhoWeAre;

import { useLoadScript } from "@react-google-maps/api";
import Map from "./components/Map";
import "./index.css";

export default function ActionArea() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBwVBqqdfD5emLYLuJ565khD2QBrphz_HI" // Add your API key
  });

  return isLoaded ? <Map /> : null;
}

import React from 'react';
import Index from './home/index';
import './index.css';
import Header from './home/components/Header';
import HeaderMenu from './home/components/HeaderMenu';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ActionArea from './pages/ActionArea/index';
import ClientSignUp from './pages/ClientSignUp';
import Nestle from './pages/products/Nestle';
import Garoto from './pages/products/Nestle';
import NestleProfessionalFood from './pages/products/NestleProfessionalFood';
import NestlePurina from './pages/products/Nestle';
import NoMatch from './pages/NoMatch';
import Footer from './home/components/Footer';

const App = (props) => {
  return (
    <Router>
      <div>
        <Header></Header>
        <HeaderMenu></HeaderMenu>
      </div>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/clientSignUp" element={<ClientSignUp />} />
        <Route path="/actionArea" element={<ActionArea />} />
        {/* products pages */}
        <Route path="/Nestle" element={<Nestle />} />
        <Route path="/Garoto" element={<Garoto />} />
        <Route
          path="/NestleProfessionalFood"
          element={<NestleProfessionalFood />}
        />
        <Route path="/NestlePurina" element={<NestlePurina />} />
        {/* notfound */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Footer> </Footer>
    </Router>
  );
};

export default App;

import React from 'react';
import './WhoDo.css'
import ReactPlayer from 'react-player'

const WhoDo = ()=> {
  return(
    <section className="u-clearfix u-valign-middle-xs u-section-4 positionWhoDo" id="sec-2066">
      <div className="u-clearfix u-sheet u-valign-middle-md u-valign-middle-sm u-sheet-1">
        <div className="u-clearfix u-expanded-width-lg u-expanded-width-md u-expanded-width-xl u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-32 u-layout-cell-2 textAbout">
                <div className="u-container-layout u-container-layout-2">
                  <h1 className="u-text u-text-default u-text-1 fontSize32">O que fazemos</h1>
                  <p className="u-text u-text-2 fontSize24">Assista ao vídeo ao lado para entender um pouco mais sobre nosso trabalho e nosso diferencial.</p>
                </div>
              </div>

              <div className="u-align-left u-layout-cell u-left-cell u-size-28 u-video u-video-1 radiusVideo">
              <ReactPlayer url='https://www.youtube.com/watch?v=BNwa8p2hyG0'
                       controls></ReactPlayer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoDo;
import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import {
  FaPhone,
  FaEnvelope,
  FaInstagram,
  FaFacebook,
  FaLinkedin,
} from 'react-icons/fa';
import './Header.css';

const Header = () => {
  return (
    <div>
      <Navbar className="brand">
        <Container>
          <Navbar.Brand className="text-white">
            <FaPhone></FaPhone>&nbsp;&nbsp;&nbsp;+55 69 21820182
          </Navbar.Brand>
          <Navbar.Brand className="text-white">
            <FaEnvelope></FaEnvelope>&nbsp;&nbsp;&nbsp;Fale Conosco
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a
                href="https://www.instagram.com/brokernorte/"
                className="text-white"
              >
                <FaInstagram size={25}></FaInstagram>
              </a>
            </Navbar.Text>
            <Navbar.Text>
              &nbsp; &nbsp;
              <a
                href="https://www.facebook.com/brokernorte"
                className="text-white"
              >
                <FaFacebook size={25}></FaFacebook>
              </a>
            </Navbar.Text>
            &nbsp; &nbsp;
            <Navbar.Text>
              <a
                href="https://www.linkedin.com/company/broker-norte/"
                className="text-white"
              >
                <FaLinkedin size={25}></FaLinkedin>
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;

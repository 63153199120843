import React from 'react';
import empresaDeCima from '../utils/empresaDeCima.jpg';
import Image from 'react-bootstrap/Image';
import './ImageAndText.css';

const ImageAndText = () => {
  return (
    <div
      className="containerItems imgWallpapaer"
      style={{
        backgroundImage: `url(${empresaDeCima})`,
        backgroundColor: '#000000',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="boxContentIBanner">
        <div className="bottom-left-title">BROKER NORTE</div>
        <div className="bottom-left-sub">
          Distribuidor Nestlé nos estados de Rondônia e Acre
        </div>
      </div>
      {/* <Image
        src={empresaDeCima}
        width={'100%'}
        className="imgWallpapaer"
      ></Image> */}
    </div>
  );
};

export default ImageAndText;

import React from 'react';
import { Container } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import logo from '../utils/logo.png';
import './HeaderMenu.css';

const HeaderMenu = () => {
  return (
    <div>
      <Navbar className="navBar" expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/" className="text-white">
                Início
              </Nav.Link>
              {/* <div className="justDropDown">
                <NavDropdown
                  title="PRODUTOS"
                  id="basic-nav-dropdown"
                  bsPrefix="colorTitleNav"
                >
                  <NavDropdown.Item href="/Garoto">Garoto</NavDropdown.Item>
                  <NavDropdown.Item href="/NestleProfessionalFood">
                    Nestlé Professional Food
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/NestlePurina">
                    Nestlé Purina
                  </NavDropdown.Item>
                </NavDropdown>
              </div> */}
              <Nav.Link
                href="https://brokernorte.vagas.solides.com.br/"
                target="_blank"
                className="text-white"
              >
                TRABALHE CONOSCO
              </Nav.Link>

              <Nav.Link
                href="https://whatsa.me/556992048743"
                target="_blank"
                className=""
                 rel="noreferrer"
                className="text-white"
                >
                CATÁLOGO DE PRODUTOS
                </Nav.Link>

              {/* <Nav.Link href="/clientSignUp" className="text-white">
                SEJA NOSSO CLIENTE
              </Nav.Link> */}
              <Nav.Link
                href="https://www.atevc.com.br/Sistema/401"
                className="text-white"
              >
                NESTLÉ ATÉ VOCÊ
              </Nav.Link>
              <Nav.Link href="/actionArea" className="text-white">
                ÁREA DE ATUAÇÃO
              </Nav.Link>
              <div className="justDropDown">
                <NavDropdown
                  title="PORTAL"
                  id="basic-nav-dropdown"
                  bsPrefix="colorTitleNav"
                >
                  <NavDropdown.Item href="https://ead.brokernorte.com.br/wp-login.php" target="_blank">
                    EAD Broker Norte
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://eadstarb.com.br/auth/login">
                    EAD Star B
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://login.convenia.com.br/"
                    target="_blank"
                  >
                    Departamento Pessoal
                  </NavDropdown.Item>
       
                  <NavDropdown.Item href="https://app.brokernorte.com.br">
                    Gestão login 
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default HeaderMenu;

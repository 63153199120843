import React, { useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

function Map() {

  const cities = [
    { 
      
      id:1 ,
      name:"Porto Velho (Sede do Broker Norte)", 
      position:{lat:-8.77804511725711,lng:-63.868745574096685}
    },
    {
      
      id:2 ,
      name:"Candeias do Jamari",
      position:{lat:-8.795828266656258,lng:-63.7003991067459}
      },
    {
      
      id: 3,
      name:"Itapuã do Oeste",
      position:{lat:-9.188682283270866,lng:-63.185649306745916}
      },
    {
      
      id:4 ,
      name:"Cujubim",
      position:{lat:-9.368751651654893,lng:-62.582640068647756}
      },
    {
      
      id: 5,
      name:"Machadinho D\u0027Oeste",
      position:{lat:-9.425214483748645,lng:-62.00244900674589}},
    {
      
      id:6 ,
      name:"Alto Paraíso - RO",
      position:{lat:-9.720731517354453,lng:-63.318846533729555}
      },
    {
      
      id:7 ,
      name:"Ariquemes",
      position:{lat:-9.904000945513562,lng:-63.03454043372957}
      },
    {
      
      id: 8,
      name:"Vale do Anari",
      position:{lat:-9.859717435196606,lng:-62.17474449140168}
      },
    {
      
      id:9 ,
      name:"Buritis",
      position:{lat:-10.212414509395174,lng:-63.827861608598326}
      },
    {
      
      id:10 ,
      name:"Monte Negro",
      position:{lat:-10.260426905986462,lng:-63.297919606745914}
      },
    {
      id: 11,
      name:"Theobroma",
    position:{lat:-10.242817247649278,lng:-62.351826162565615}
    },
    {
      id:12 ,
      name:"Jaru",
    position:{lat:-10.428345753621869,lng:-62.473689793254074}
    },
    {
      id:13 ,
      name:"Vale do Paraíso",
    position:{lat:-10.427344214617131,lng:-62.12974900674591}
    },
    {
      id: 14,
      name:"Campo Novo de Rondônia",
    position:{lat:-10.57026022148415,lng:-63.61891577790985}
    },
    {
      id: 15,
      name:"Ouro Preto do Oeste",
    position:{lat:-10.721003038464309,lng:-62.25597493558195}
    },
    {
      id: 16,
      name:"Nova União",
    position:{lat:-10.915226645256887,lng:-62.55703860674591}
    },
    {
      id: 17,
      name:"Teixeiropolis",
    position:{lat:-10.936475803481912,lng:-62.25598441468048}
    },
    {
      id:18 ,
      name:"Ji-Paraná",
    position:{lat:-10.872426886017967,lng:-61.959251149073765}
    },
    {
      id: 19,
      name:"Mirante da Serra",
    position:{lat:-11.030659356746801,lng:-62.67294954907379}
    },
    {
      id:20 ,
      name:"Urupá",
    position:{lat:-11.124473188247807,lng:-62.36348110489348}
    },
    {
      id: 21,
      name:"Presidente Médici",
    position:{lat:-11.169198541356197,lng:-61.90167136441803}
    },
    {
      id: 22,
      name:"Cacoal",
    position:{lat:-11.431997199622282,lng:-61.45480562023772}
    },
    {
      id: 23,
      name:"Espigão D\u0027Oeste",
    position:{lat:-11.525729427729672,lng:-61.01162033558197}
    },
    {
      id: 24,
      name:"Pimenta Bueno",
    position:{lat:-11.677517313956614,lng:-61.178678433729566}
    },
    {
      id: 25,
      name:"Rolim de Moura",
    position:{lat:-11.725856202396033,lng:-61.7781248067459}
    },
    {
      id: 26,
      name:"Novo Horizonte do Oeste",
    position:{lat:-11.707276316889606,lng:-61.99824399140169}
    },
    {
      id:27 ,
      name:"São Miguel do Guaporé",
    position:{lat:-11.69395636263279,lng:-62.71447544907379}
    },
    {
      id: 28,
      name:"Seringueiras",
    position:{lat:-11.765490363090706,lng:-63.03141924722138}
    },
    {
      id: 29,
      name:"Alta Floresta D\u0027Oeste",
    position:{lat:-11.929275022968776,lng:-61.99422550674591}
    },
    {
      id: 30,
      name:"Santa Luzia D\u0027Oeste",
    position:{lat:-11.906511980954035,lng:-61.77974469325411}
    },
    {
      id:31 ,
      name:"São Felipe D\u0027Oeste",
    position:{lat:-11.906313655361433,lng:-61.5138026392868}
    },
    {
      id: 32,
      name:"Alto Alegre dos Parecis",
    position:{lat:-12.132670835159164,lng:-61.856619591401675}
    },
    {
      id:33 ,
      name:"São Francisco do Guaporé",
    position:{lat:-12.060151595159896,lng:-63.569201893254096}
    },
    {
      id: 34,
      name:"Vilhena",
    position:{lat:-12.738538539769763,lng:-60.12216729325407}
    },
    {
      id: 35,
      name:"Cabixi",
    position:{lat:-13.494734398305818,lng:-60.54259903743437}
    },
    {
      id: 36,
      name:"Cerejeiras",
    position:{lat:-13.18905383491689,lng:-60.82080083558197}
    },
    {
      id: 37,
      name:"Alto Guarajus",
    position:{lat:-12.983710738073585,lng:-61.038656979762266}
    },
    {
      id:38 ,
      name:"Nova Mamoré",
    position:{lat:-10.410759472365433,lng:-65.33056716441804}
    },
    {
      id: 39,
      name:"Guajará-Mirim",
    position:{lat:-10.78723082013777,lng:-65.34099248161469}
    },
    {
      id: 40,
      name:"Acrelândia",
    position:{lat:-10.076505694214449,lng:-67.0589033276474}
    },
    {
      id: 41,
      name:"Plácido de Castro",
    position:{lat:-10.330826987666349,lng:-67.18679766441802}
    },
    {
      id:42 ,
      name:"Capixaba",
    position:{lat:-10.576360964916004,lng:-67.67706987843475}
    },
    {
      id:43 ,
      name:"Xapuri",
    position:{lat:-10.652734300631646,lng:-68.50371112023772}
    },
    {
      id: 44,
      name:"Rio Branco",
    position:{lat:-9.973891495581404,lng:-67.80945968399202}
    },
    {
      id:45 ,
      name:"Senador Guiomard (Quinarí)",
    position:{lat:-10.15307283799344,lng:-67.73835910304108}
    },
    {
      id: 46,
      name:"Brasiléia",
    position:{lat:-10.99744681453174,lng:-68.75311120489349}},
    {
      id: 47,
      name:"Sena Madureira",
    position:{lat:-9.068555396522125,lng:-68.65463052209014}},
    {
      id: 49,
      name:"Feijó",
    position:{lat:-8.167499530091913,lng:-70.3574010490738}
    },
    {
      id: 50,
      name:"Tarauacá",
    position:{lat:-8.16095393739696,lng:-70.7653079816147}
    },
    {
      id: 51,
      name:"Mâncio Lima",
    position:{lat:-7.613521198894894,lng:-72.90596823558197}
    },
    {
      id: 52,
      name:"Cruzeiro do Sul",
    position:{lat:-7.636902290019232,lng:-72.6701692490738}
    }
  ];

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    cities.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100vw", height: "100vh" }}
    >
      {cities.map(({ id, name, position }) => (
        <Marker
          key={id}
          position={position}
          onClick={() => handleActiveMarker(id)}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{name}</div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default Map;

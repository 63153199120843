import React from 'react';
import { Container } from 'react-bootstrap';
import './ActionArea.css';
import { FaMapMarked, FaPortrait, FaBuilding } from 'react-icons/fa';

const ActionArea = () => {
  return (
    <section className="sectionBox">
      <Container>
        <div className="row g-4 row-cols-1 row-cols-lg-3">
          <div className="card border-0  rounded-xs ">
            <div className="card-body">
              <div className="text-center">
                <FaMapMarked
                  size={50}
                  className="text-dark elementor-icon-area"
                ></FaMapMarked>
              </div>
              <h4 className="mt-4 mb-3 text-center">Área de atuação</h4>
              <p>
                Conheça as cidades e municípios que atendemos nos estados de
                Rondônia e Acre.
              </p>
              <p className="text-center">
                <a
                  href="https://brokernorte.com.br/cidades-atendidas/"
                  className="btn btn-warning text-white"
                >
                  Saiba mais
                </a>
              </p>
            </div>
          </div>

          <div className="card border-0  rounded-xs ">
            <div className="card-body">
              <div className="text-center">
                <FaPortrait
                  size={50}
                  className="text-dark elementor-icon-work"
                ></FaPortrait>
              </div>

              <h4 className="mt-4 mb-3 text-center">Trabalhe conosco</h4>
              <p>
                Quer fazer parte do nosso time? Cadastre seu currículo em uma
                vaga disponível ou em nosso banco de currículos para participar
                de nossos processos seletivos.
              </p>
              <p className="text-center">
                <a
                  href="https://brokernorte.solides.jobs"
                  className="btn btn-warning text-white"
                >
                  Saiba mais
                </a>
              </p>
            </div>
          </div>

          <div className="card border-0  rounded-xs ">
            <div className="card-body">
              <div className="text-center">
                <FaBuilding
                  size={50}
                  className="text-dark elementor-icon-customer"
                ></FaBuilding>
              </div>
              <h4 className="mt-4 mb-3 text-center">Seja nosso cliente</h4>
              <p>
                Cadastre sua empresa para receber um atendimento especializado.
                Em breve nossa equipe entrará em contato com você.
              </p>
              <p className="text-center">
                <a
                  href="https://brokernorte.com.br/seja-nosso-cliente/"
                  className="btn btn-warning text-white"
                >
                  Saiba mais
                </a>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ActionArea;

import React from 'react';
import './KnowWeEstructure.css';
import ReactPlayer from 'react-player';
import './Footer.css';
import video from '../utils/videoBrokerWhere.mp4';

const KnowWeEstructure = () => {
  return (
    <section
      className="u-clearfix u-section-6 sectionKnowWeEstructure footerSpace "
      id="sec-49bb"
    >
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-gutter-50 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-layout-cell u-left-cell u-size-28 u-video u-video-1 radiusVideo">
                <ReactPlayer url={video} controls></ReactPlayer>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2">
                <div className="u-container-layout u-valign-middle u-container-layout-2">
                  <h2 className="u-text u-text-1 fontSize32">
                    Conheça a nossa estrutura
                  </h2>
                  <p className="u-text u-text-2 fontSize24">
                    {' '}
                    Embarque no vídeo ao lado para conhecer um pouco da nossa
                    sede em Porto Velho-RO.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KnowWeEstructure;
